import React, { useMemo } from 'react';
import { parseDate } from '../utils/parseDate';
import { parseSEK } from '../utils/parseSEK';
import Button from './Button';
import Card from './Card';
import DotsDropdown from './DotsDropdown';
import { ServicelogV2BM } from '@bm-js/h2o-shared';

type Props = {
  entry: ServicelogV2BM;
  deleteEntryInit: (entry: ServicelogV2BM) => void;
  editEntryInit: (entry: ServicelogV2BM) => void;
  downloadPdf: (entry: ServicelogV2BM) => void;
  previewPdf: (entry: ServicelogV2BM) => void;
};

const ServicelogCard = ({
  entry,
  deleteEntryInit,
  editEntryInit,
  downloadPdf,
  previewPdf,
}: Props) => {
  const totalSum = useMemo(() => {
    const rowSums = entry.table.map((row) => {
      return row.priceExVat * row.quantity;
    });
    const totalSum = rowSums.reduce((acc, curr) => acc + curr, 0);
    return totalSum;
  }, [entry]);
  return (
    <div>
      <Card className="order-grid">
        <p className="mobile">{entry.offerNumber}</p>
        <p>{parseDate(entry.offerDate)}</p>
        <p>{entry.table.length}</p>
        <p>{parseSEK(totalSum)}</p>
        <p className="mobile">{entry.status}</p>
        <DotsDropdown
          content={
            <>
              <Button small onClick={() => previewPdf(entry)}>
                Förhandsvisa
              </Button>
              <Button small onClick={() => downloadPdf(entry)}>
                Ladda ned
              </Button>
              <Button small onClick={() => editEntryInit(entry)}>
                Redigera
              </Button>
              <Button
                small
                onClick={() => deleteEntryInit(entry)}
                look="danger"
              >
                Radera
              </Button>
            </>
          }
        />
      </Card>
    </div>
  );
};

export default ServicelogCard;
